import {WOW} from '../../../node_modules/wowjs/dist/wow';

new WOW({
  offset: 90,
  mobile: false
}).init();


let istextList = localStorage.getItem('istextListstate')

$(document).ready(function(){
  if(istextList == 'false'){
    $('.switch-btn').removeClass('show-text-list')
    $('.switch-list').removeClass('text-list-show')
  }else if(istextList == 'true'){
    $('.switch-btn').addClass('show-text-list')
    $('.switch-list').addClass('text-list-show')
  }

  if(window.location.href.indexOf('#md') != -1){
    if($('.ny-banner').length > 0){
      let headerH = 0
      if($(window).width()> 1024){
        headerH = $('.header').outerHeight() * 0.7272
      }else{
        headerH = $('.header').outerHeight()
      }
      let h = $('.ny-banner').offset().top + $('.ny-banner').outerHeight() - headerH
      $(document).scrollTop(h)
    }
  }
})

$('.switch-btn .img-switch').on('click',function(){
  $(this).closest('.switch-btn').removeClass('show-text-list')
  $('.switch-list').removeClass('text-list-show')
  localStorage.setItem('istextListstate', false);
})
$('.switch-btn .text-switch').on('click',function(){
  $(this).closest('.switch-btn').addClass('show-text-list')
  $('.switch-list').addClass('text-list-show')
  localStorage.setItem('istextListstate', true);
})



//模拟下拉
$('.select input').on('click',function(){
  let $parent = $(this).closest('.select')
  $parent.siblings('.select').removeClass('hover')
  if(!$parent.hasClass('hover')){
    $parent.addClass('hover')
  }
})

$('.select .select-list').on('click','li',function(){
  let that = $(this)
  let val = $(this).html()
  that.closest('.select').find('input').val(val)
  that.closest('.select').removeClass('hover')
  
  setTimeout(function(){
    that.addClass('on').siblings().removeClass('on')
  },200)
})

$('body').on('click',function(e){
  if($(e.target).parents('.form-item.select').length == 0){
    $('.form-item.select').removeClass('hover')
  }
})

//国家选择下拉填充
var geolocation= [
  ["AO", "Angola"],
  ["AF", "Afghanistan"],
  ["AL", "Albania"],
  ["DZ", "Algeria"],
  ["AD", "Andorra"],
  ["AI", "Anguilla"],
  ["AG", "Barbuda Antigua"],
  ["AR", "Argentina"],
  ["AM", "Armenia"],
  ["AU", "Australia"],
  ["AT", "Austria"],
  ["AZ", "Azerbaijan"],
  ["BS", "Bahamas"],
  ["BH", "Bahrain"],
  ["BD", "Bangladesh"],
  ["BB", "Barbados"],
  ["BY", "Belarus"],
  ["BE", "Belgium"],
  ["BZ", "Belize"],
  ["BJ", "Benin"],
  ["BM", "Bermuda Is."],
  ["BO", "Bolivia"],
  ["BW", "Botswana"],
  ["BR", "Brazil"],
  ["BN", "Brunei"],
  ["BG", "Bulgaria"],
  ["BF", "Burkina-faso"],
  ["MM", "Burma"],
  ["BI", "Burundi"],
  ["CM", "Cameroon"],
  ["CA", "Canada"],
  ["CF", "Central African Republic"],
  ["TD", "Chad"],
  ["CL", "Chile"],
  ["CN", "China"],
  ["CO", "Colombia"],
  ["CG", "Congo"],
  ["CK", "Cook Is."],
  ["CR", "Costa Rica"],
  ["CU", "Cuba"],
  ["CY", "Cyprus"],
  ["CZ", "Czech Republic"],
  ["DK", "Denmark"],
  ["DJ", "Djibouti"],
  ["DO", "Dominica Rep."],
  ["EC", "Ecuador"],
  ["EG", "Egypt"],
  ["SV", "EI Salvador"],
  ["EE", "Estonia"],
  ["ET", "Ethiopia"],
  ["FJ", "Fiji"],
  ["FI", "Finland"],
  ["FR", "France"],
  ["GF", "French Guiana"],
  ["GA", "Gabon"],
  ["GM", "Gambia"],
  ["GE", "Georgia"],
  ["DE", "Germany"],
  ["GH", "Ghana"],
  ["GI", "Gibraltar"],
  ["GR", "Greece"],
  ["GD", "Grenada"],
  ["GU", "Guam"],
  ["GT", "Guatemala"],
  ["GN", "Guinea"],
  ["GY", "Guyana"],
  ["HT", "Haiti"],
  ["HN", "Honduras"],
  ["HK", "Hongkong"],
  ["HU", "Hungary"],
  ["IS", "Iceland"],
  ["IN", "India"],
  ["ID", "Indonesia"],
  ["IR", "Iran"],
  ["IQ", "Iraq"],
  ["IE", "Ireland"],
  ["IL", "Israel"],
  ["IT", "Italy"],
  ["JM", "Jamaica"],
  ["JP", "Japan"],
  ["JO", "Jordan"],
  ["KH", "Kampuchea (Cambodia )"],
  ["KZ", "Kazakstan"],
  ["KE", "Kenya"],
  ["KR", "Korea"],
  ["KW", "Kuwait"],
  ["KG", "Kyrgyzstan"],
  ["LA", "Laos"],
  ["LV", "Latvia"],
  ["LB", "Lebanon"],
  ["LS", "Lesotho"],
  ["LR", "Liberia"],
  ["LY", "Libya"],
  ["LI", "Liechtenstein"],
  ["LT", "Lithuania"],
  ["LU", "Luxembourg"],
  ["MO", "Macao"],
  ["MG", "Madagascar"],
  ["MW", "Malawi"],
  ["MY", "Malaysia"],
  ["MV", "Maldives"],
  ["ML", "Mali"],
  ["MT", "Malta"],
  ["MU", "Mauritius"],
  ["MX", "Mexico"],
  ["MD", "Moldova"],
  ["MC", "Monaco"],
  ["MN", "Mongolia"],
  ["MS", "Montserrat Is."],
  ["MA", "Morocco"],
  ["MZ", "Mozambique"],
  ["NA", "Namibia"],
  ["NR", "Nauru"],
  ["NP", "Nepal"],
  ["NL", "Netherlands"],
  ["NZ", "New Zealand"],
  ["NI", "Nicaragua"],
  ["NE", "Niger"],
  ["NG", "Nigeria"],
  ["KP", "North Korea"],
  ["NO", "Norway"],
  ["OM", "Oman"],
  ["PK", "Pakistan"],
  ["PA", "Panama"],
  ["PG", "Papua New Cuinea"],
  ["PY", "Paraguay"],
  ["PE", "Peru"],
  ["PH", "Philippines"],
  ["PL", "Poland"],
  ["PF", "French Polynesia"],
  ["PT", "Portugal"],
  ["PR", "Puerto Rico"],
  ["QA", "Qatar"],
  ["RO", "Romania"],
  ["RU", "Russia"],
  ["LC", "Saint Lueia"],
  ["VC", "Saint Vincent"],
  ["SM", "San Marino"],
  ["ST", "Sao Tome and Principe"],
  ["SA", "Saudi Arabia"],
  ["SN", "Senegal"],
  ["SC", "Seychelles"],
  ["SL", "Sierra Leone"],
  ["SG", "Singapore"],
  ["SK", "Slovakia"],
  ["SI", "Slovenia"],
  ["SB", "Solomon Is."],
  ["SO", "Somali"],
  ["ZA", "South Africa"],
  ["ES", "Spain"],
  ["LK", "Sri Lanka"],
  ["SD", "Sudan"],
  ["SR", "Suriname"],
  ["SZ", "Swaziland"],
  ["SE", "Sweden"],
  ["CH", "Switzerland"],
  ["SY", "Syria"],
  ["TW", "Taiwan"],
  ["TJ", "Tajikstan"],
  ["TZ", "Tanzania"],
  ["TH", "Thailand"],
  ["TG", "Togo"],
  ["TO", "Tonga"],
  ["TT", "Trinidad and Tobago"],
  ["TN", "Tunisia"],
  ["TR", "Turkey"],
  ["TM", "Turkmenistan"],
  ["UG", "Uganda"],
  ["UA", "Ukraine"],
  ["AE", "United Arab Emirates"],
  ["GB", "United Kiongdom"],
  ["US", "United States of America"],
  ["UY", "Uruguay"],
  ["UZ", "Uzbekistan"],
  ["VE", "Venezuela"],
  ["VN", "Vietnam"],
  ["YE", "Yemen"],
  ["YU", "Yugoslavia"],
  ["ZW", "Zimbabwe"],
  ["ZR", "Zaire"],
  ["ZM", "Zambia"]];

var countryHtml = ''
for (let i=0; i < geolocation.length; i++){
  countryHtml += `<li data-val="${geolocation[i][1]}">${geolocation[i][1]}</li>`
}
$('#country_list').html(countryHtml)