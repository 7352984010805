module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="load" id="load">\r\n  <!-- 进度条 -->\r\n  <span class="load-progress" id="load-progress"></span>\r\n  <!-- 上结构 -->\r\n  <div class="load-top">\r\n    <img class="load-logo" src="' +
((__t = (require('../../assets/images/logo2.png'))) == null ? '' : __t) +
'" alt="">\r\n  </div>\r\n  <!-- 下结构 -->\r\n  <div class="load-bottom">\r\n    <dl>\r\n      <dt>Loading</dt>\r\n      <dd class="load-num" id="load-num" data-num="0">%</dd>\r\n    </dl>\r\n  </div>\r\n</section>';

}
return __p
}