import './index.scss'

$('[data-open="cmenu"]').on('click', function () {
  if($('header .hamburger').hasClass('active')){
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('header .menu-mask').fadeOut(200)
  }else{
    $('header .hamburger').addClass('active')
    $('header .mob-header').addClass('show')
    $('header .menu-mask').fadeIn(200)
  }
})

$('.mob-header ul li .sub-nav a').on('click',function(){
  setTimeout(function(){
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('header .menu-mask').fadeOut(200)
  },200)
})

$('.mob-header ul li .title .iconfont').on('click',function(){
  let $parentEl = $(this).parents('li')
  if($parentEl.hasClass('active')){
    $parentEl.removeClass('active')
    $parentEl.find('.sub-nav').slideUp()
  }else{
    $parentEl.addClass('active').siblings().removeClass('active')
    $parentEl.find('.sub-nav').slideDown()
    $parentEl.siblings().find('.sub-nav').slideUp()
  }
})

$('.header .header-search .search-btn').on('click',function(){
  let $parentEl = $(this).parents('.header-search')
  if($parentEl.hasClass('active')){
    $parentEl.removeClass('active')
  }else{
    $parentEl.addClass('active')
  }
})

$('body').on('click',function(e){
  if($(e.target).parents('.header .header-search').length == 0){
    $('.header .header-search').removeClass('active')
  }
})

var navover_tid = [];
var navout_tid = [];
$(document).ready(function(){
  $('.mob-header ul li').each(function(index){
    if($(this).find('.sub-nav a').length > 0){
      $(this).find('.title .iconfont').css('display','flex')
    }
  })

  $(".header .header-nav li").each(function (index) {
    $(this).hover(
      function () {
        var _self = this;
        clearTimeout(navout_tid[ index ]);
        navover_tid[ index ] = setTimeout(function () {
          $(_self).addClass('hover');
        }, 201);
      },
      function () {
        var _self = this;
        clearTimeout(navover_tid[ index ]);
        navout_tid[ index ] = setTimeout(function () {
          $(_self).removeClass('hover');
        }, 201);
    });
  });
})

$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if(scroH > 100){
    $('.header').addClass('scroll')
  }else{
    $('.header').removeClass('scroll')
  }

  if(scroH > 500){
    $('footer .backtop').addClass('active')
    $('footer .aside-share').addClass('active')
  }else{
    $('footer .backtop').removeClass('active')
    $('footer .aside-share').removeClass('active')
  }
});
