import './index.scss'

$(document).ready(function(){
  $('footer .footer-nav ul li .title .iconfont').on('click',function(){
    let $parent = $(this).closest('li')
    if($parent.hasClass('active')){
      $parent.removeClass('active').find('.sub-nav').slideUp()
    }else{
      $parent.siblings().removeClass('active').find('.sub-nav').slideUp()
      $parent.addClass('active').find('.sub-nav').slideDown()
    }
  })
  
  $('footer .backtop').on('click',function(){
    $("html, body").animate({
      scrollTop: 0
    }, {duration: 500,easing: "swing"});
    return false;
  })
  
  $('footer .aside-share .aside-share-btn').on('click',function(){
    let $parent = $(this).closest('.aside-share')
    if($parent.hasClass('trans')){
      $parent.removeClass('trans')
    }else{
      $parent.addClass('trans')
    }
  })

  if($(window).width() <= 1024){
    $('footer .aside-share').addClass('trans')
  }
})