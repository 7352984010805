module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="ny-banner">\r\n  <div class="crumbs">\r\n    <a href="#">Home</a><span>></span>\r\n    <a href="#">About Us</a>\r\n  </div>\r\n  <div class="left">\r\n    <div class="banner-cont">\r\n      <div class="banner-title bold">About Us</div>\r\n      <!-- <div class="banner-search">\r\n        <form action="">\r\n          <input type="text" placeholder="Input Product Keywords">\r\n          <button aria-label="search"><i class="iconfont icon-sousuo1"></i></button>\r\n        </form>\r\n      </div> -->\r\n      <!-- <div class="banner-desc">At ComNav Technology we believe that our technology innovations make your work easier and more efficient.</div> -->\r\n      <!-- <div class="banner-link">\r\n        <a href="#">Company Overview</a>\r\n        <a href="#">Milestone</a>\r\n        <a href="#">Honor & Certificate</a>\r\n      </div> -->\r\n    </div>\r\n  </div>\r\n  <div class="right bg-cover" style="background-image: url(' +
((__t = (require('../../assets/images/banner_about.jpg'))) == null ? '' : __t) +
');"></div>\r\n</section>';

}
return __p
}